<template>
  <div class="background">
    <ul class="list">
      <li
        v-for="(top, k) in data"
        :key="k"
      >
      <div id="img"><img :src="icon[k]" width="30px" /></div>
      <div id="name">{{ top.name }}</div>
      <div id="money">{{ top.money }}</div>
    </li>
    </ul>
  </div>
</template>
<script>

import io from 'socket.io-client'

export default {
  data() {
    return {
      socket: {},
      data: [],
      icon: [
        // eslint-disable-next-line global-require
        require('@/assets/images/top/1.png'),
        // eslint-disable-next-line global-require
        require('@/assets/images/top/2.png'),
        // eslint-disable-next-line global-require
        require('@/assets/images/top/3.png'),
      ],
    }
  },
  created() {
    this.socket = io(process.env.VUE_APP_SOCKET_SERVE)
    this.$http.get(`/widgets/token/${this.$route.params.id}`).then(({ data }) => {
      this.data = data.json
    })
      .catch(() => {
        this.$router.push({ name: 'error-404' })
      })
  },
  mounted() {
    this.socket.emit('join room', {
      id: this.$route.params.id,
    })

    this.socket.on('new_comment', data => {
      this.data = data.data
    })
  },
}
</script>
<style>
html[dir] body{
  background: none !important;
}
.list {
  margin-top: 100px;
  margin-left: 100px;
  display: inline-grid;
}

.list li:first-child{
  margin-left: -10px;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.list li:first-child div#img img{
  height: 35px;
  width: 35px;
}

.list li:first-child div#name{
  font-size: 25px;
  padding-top: 2px !important;
}

.list li:first-child div#money{
  font-size: 22px;
}
.list li{
  list-style: none;
  background: linear-gradient(rgb(157, 255, 0), pink);
  border-bottom-style: solid;
  border-bottom-width: thin;
  padding: 2px;
  border-left-style: solid;
  display: inline;
}

.list li div#name{
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  max-width: 300px;
  min-width: 200px;
  font-size: 22px;
  font-weight: bold;
  float: left;
  padding-left: 5px;
}
.list li div#money{
  float: right;
  padding-left: 5px;
  font-weight: bold;
  color: rgb(98, 0, 255);
}

.list li div#img{
  float: left;
}

</style>
